import React from "react";

const GraphImage = ({ sizes, original, lazy = false, ...props }) => {
    const newSizes = sizes && sizes.map((size, index, arr) => {
        return { sourceUrl: size.sourceUrl, width: size.width, mimeType: size.mimeType };
    });
    return (
        <picture>
            {newSizes && newSizes.map((item, index) => (
                <React.Fragment key={item.width + index}>
                    <source
                        media={`(min-width: ${item.width}px)`}
                        srcSet={`${item.sourceUrl}.webp`}
                        type={'image/webp'}
                    />

                    <source
                        media={`(min-width: ${item.width}px)`}
                        srcSet={`${item.sourceUrl}`}
                        type={item.mimeType}
                    />
                </React.Fragment>
            ))}
            <img src={original} {...props} loading={lazy ? 'lazy' : 'eager'}/>
        </picture>
    )
}

export default GraphImage;